<script setup>

import { defineProps, defineModel } from 'vue'

const props = defineProps({
    label: {type: String, required: true},
    fieldId: {type: String, required: true},
    fieldType: {type: String, default: "text"},
    labelSize: {type: Number, default: 2},
    fieldSize: {type: Number, default: 10},
})

const fieldValue = defineModel({required: true})

const fieldAttr = {
    type: props.fieldType,
    id: props.fieldId,
}

</script>

<template>
    <div class="mb-3">
        <label :for="fieldId" :class="['col-sm-' + labelSize, 'col-form-label']">{{ label }}</label>
        <div :class="['col-sm-' + fieldSize]">
            <input v-bind="fieldAttr" class="form-control" v-model="fieldValue"/>
        </div>
    </div>
</template>