<script setup>
import { inject } from "vue";
import LinkItem from './components/LinkItem.vue'

const HCP = inject('HCP')
</script>
<style>
@import'~bootstrap/dist/css/bootstrap.css'
</style>
<template>
  <h1>Home Control Panel</h1>
  <ul class="nav nav-tabs">
    <LinkItem to="/">Dashboard</LinkItem>
    <LinkItem v-if="!HCP.auth.value" to="/connect">Connect</LinkItem>
    <LinkItem v-if="HCP.auth.value" to="/door">Doors</LinkItem>
    <LinkItem to="/settings">Settings</LinkItem>
  </ul>
  <main>
    <router-view></router-view>
  </main>
</template>