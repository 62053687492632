
import { reactive, ref, watch } from 'vue'
import mqtt from 'mqtt'
import { browserStreamBuilder } from 'mqtt/lib/connect/ws'

function removeExpired(devicesList, expireDevice) {
    const now = parseInt(Date.now() / 1000)
    for (let deviceName in devicesList)
        if ((now - devicesList[deviceName].lastUpdateAt) > expireDevice)
            delete devicesList[deviceName]
    return devicesList
}

export class HomeControlPanel {
    constructor(app_options) {
        const localStorage = window.localStorage
        this.auth = ref(false)
        this.connAckCode = ref(0)
        this.lastError = ref(null)
        this.options = app_options
        this.devices = {
            locks: reactive(
                removeExpired(
                    JSON.parse(localStorage.getItem('devices_locks') || '{}'),
                    this.options.expire_device.value))
        }
        watch(
            this.devices.locks,
            (locks) => localStorage.setItem('devices_locks', JSON.stringify(locks)))
        this.client = new mqtt.MqttClient(
            (client) => {
                delete this.options.mqtt_options.hostname  // delete cached host
                return browserStreamBuilder(client, this.options.mqtt_options)},
            this.options.mqtt_options)
        this.client.on('connect', (connack) => {
            this.connAckCode.value = connack.returnCode
            if (connack.returnCode == 0) {
                this.auth.value = true
                if (!connack.sessionPresent)
                    this.client.subscribe('zigbee2mqtt/+')
            }
        })
        this.client.on('close', () => {
            this.auth.value = false
        })
        this.client.on('message', (topic, message) => {
            const matchTopic = topic.match(/^zigbee2mqtt\/([^/]+)/)

            if (matchTopic == null)
                return

            const deviceState = JSON.parse(message.toString())

            if ('lock_state' in deviceState)
                this.devices.locks[matchTopic[1]] = {
                    lastUpdateAt: parseInt(Date.now() / 1000),
                    data: deviceState
                }
        })
        this.client.on('error', (err) => this.lastError.value = err.message)
    }

    connect() {
        if (this.client.connected)
            this.client.reconnect()
        else
            this.client.connect()
    }

    deviceLock(deviceId) {
        if (!this.auth.value)
            throw new Error('User not authenticated')
        if (!(deviceId in this.devices.locks))
            throw new Error(`Lock device not found: ${deviceId}`)
        this.client.publish(`zigbee2mqtt/${deviceId}/set`, JSON.stringify({state: 'LOCK'}))
    }

    deviceUnlock(deviceId) {
        if (!this.auth.value)
            throw new Error('User not authenticated')
        if (!(deviceId in this.devices.locks))
            throw new Error(`Lock device not found: ${deviceId}`)
        this.client.publish(`zigbee2mqtt/${deviceId}/set`, JSON.stringify({state: 'UNLOCK'}))
    }
}
