<script setup>
import { computed, inject } from "vue"


const HCP = inject('HCP')

const connectionStatus = computed(() => HCP.auth.value ? "Connected" : "Not Connected")

const classStatus = computed(() => {
    const theme = HCP.auth.value ? "success" : "danger"

    return [`text-${theme}-emphasis`, `bg-${theme}-subtle`]
})

const errorMessage = computed(() => {
    const errorList = ["-"]

    errorList[0x81] = "Malformed Packet"
    errorList[0x82] = "Protocol Error"
    errorList[0x84] = "Unsupported Protocol Version"
    errorList[0x85] = "Client Identifier not valid"
    errorList[0x86] = "Bad User Name or Password"
    errorList[0x8a] = "Banned"
    errorList[0x95] = "Packet too large"

    if (HCP.connAckCode.value)
        return errorList[HCP.connAckCode.value] || `Error code: ${HCP.connAckCode.value}`
    else if (HCP.lastError.value)
        return HCP.lastError.value
    return ""
})

const hasError = computed(() => Boolean(HCP.connAckCode.value || HCP.lastError.value))
</script>
<style>
#table-settings th {
    width: 0;
    min-width: fit-content;
}
</style>
<template>
    <table class="table" id="table-settings">
        <tbody>
            <tr>
                <th scope="row" class="text-end">Status</th>
                <td :class="classStatus">{{ connectionStatus }}</td>
            </tr>
            <tr>
                <th scope="row" class="text-end">Error</th>
                <td :class="{'text-danger': hasError}">{{ errorMessage }}</td>
            </tr>
            <tr>
                <td colspan="2"><button type="button" class="btn btn-success" :disabled="HCP.auth.value" @click="HCP.connect">Connect</button></td>
            </tr>
        </tbody>
    </table>
</template>