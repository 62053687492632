<script setup>
import { defineProps } from 'vue'

const props = defineProps({date: Date})

const longDate = (date) => date.toString()

const shortDate = (date) => {
    const year = date.getFullYear().toString()
    const mon = ('0' + (date.getMonth() + 1)).slice(-2)
    const day = ('0' + date.getDate()).slice(-2)
    const hour = ('0' + date.getHours()).slice(-2)
    const min = ('0' + date.getMinutes()).slice(-2)
    const sec = ('0' + date.getSeconds()).slice(-2)

    return `${year}-${mon}-${day} ${hour}:${min}:${sec}`
}

</script>
<template>
    <span class="d-none d-lg-inline">{{ longDate(props.date) }}</span>
    <span class="d-lg-none">{{ shortDate(props.date) }}</span>
</template>