import { createMemoryHistory, createRouter } from 'vue-router'

import ConnectView from './views/ConnectView.vue'
import DoorControlView from './views/DoorControlView.vue'
import MainDashboardView from './views/MainDashboardView.vue'
import SettingsView from './views/SettingsView.vue'

const routes = [
    {path: '/', component: MainDashboardView},
    {path: '/connect', component: ConnectView},
    {path: '/door', component: DoorControlView},
    {path: '/settings', component: SettingsView},
]

const router = createRouter({
    history: createMemoryHistory(process.env.BASE_URL),
    routes,
})

export default router

