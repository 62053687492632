<script setup>

import FormField from '@/components/FormField'
import { inject } from 'vue'

const AppSettings = inject('AppSettings')
const HCP = inject('HCP')

</script>
<template>
    <form>
        <fieldset class="border p-2">
            <legend class="float-none w-auto p-2">MQTT Settings</legend>
            <FormField label="IP Address/Host" fieldId="mqttHost" v-model="AppSettings.mqtt_options.host"/>
            <FormField label="Port" fieldId="mqttPort" fieldType="number" v-model="AppSettings.mqtt_options.port"/>
            <FormField label="Username" fieldId="mqttUsername" v-model="AppSettings.mqtt_options.username"/>
            <FormField label="Password" fieldId="mqttPassword" fieldType="password" v-model="AppSettings.mqtt_options.password"/>
            <button type="button" class="btn btn-success" :disabled="HCP.auth.value" @click="HCP.connect">Connect</button>
        </fieldset>
    </form>
</template>
