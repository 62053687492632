import { createApp, reactive, ref, watch } from 'vue'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'
import { HomeControlPanel } from './controlpanel'

const app = createApp(App)
const localStorage = window.localStorage

const getMqttClientId = (refresh) => {
    const session_id = Math.random().toString(16).substring(2, 6)
    let client_id = refresh ? null : localStorage.getItem('MQTT_clientid')

    if (!client_id) {
        client_id = Math.random().toString(16).substring(2, 6)
        localStorage.setItem('MQTT_clientid', client_id)
    }
    return `HCP_App_${client_id}_${session_id}`
}

const protocol = ref(localStorage.getItem('MQTT_protocol') || 'wss')
watch(protocol, (value) => localStorage.setItem('MQTT_protocol', value))
const host = ref(localStorage.getItem('MQTT_host') || '192.168.1.1')
watch(host, (value) => localStorage.setItem('MQTT_host', value))
const port = ref(parseInt(localStorage.getItem('MQTT_port') || 9001))
watch(port, (value) => localStorage.setItem('MQTT_port', value))
const username = ref(localStorage.getItem('MQTT_username') || 'username')
watch(username, (value) => localStorage.setItem('MQTT_username', value))
const password = ref(localStorage.getItem('MQTT_password') || 'password')
watch(password, (value) => localStorage.setItem('MQTT_password', value))
const expire_device = ref(parseInt(localStorage.getItem('expire_device') || 86400))
watch(expire_device, (value) => localStorage.setItem('expire_device', value))

const AppSettings = reactive({
    expire_device, 
    mqtt_options: {
        protocol, host, port, username, password,
        clientId: getMqttClientId(),
        // log: console.log,
        // createWebsocket: (url, protocols) => {
        //     console.log(`URL: ${url} -> ${protocols}`)
        //     return new WebSocket(url, protocols)
        // }
        }})

app.provide('getMqttClientId', getMqttClientId)
app.provide('AppSettings', AppSettings)
app.provide('HCP', new HomeControlPanel(AppSettings))

app.use(router).mount('#app')
